<template>
  <div class="fullscreen" v-loading="loading">
    <div
      class="centered-box"
      v-if="formbuilderDetails && !isExpiredFormbuilder"
    >
      <div
        class="top-bar"
        v-if="formbuilderDetails.customization.show_title_bar"
      >
        <div class="title ml-1">
          <span style="font-weight: 500; font-size: 22px; color: #030503">
            {{ formbuilderDetails.name }}
          </span>
        </div>
        <div class="image-container mr-1">
          <img
            v-if="formbuilderDetails.logo"
            :src="formbuilderDetails.logo"
            alt="Form builder Logo"
            style="max-height: 40px"
          />
          <img
            v-else-if="companyLogo"
            :src="companyLogo"
            alt="Nimble logo"
            style="max-height: 40px"
          />
          <img
            v-else-if="!logoTwoLoading"
            src="@/assets/Esigns_Black.svg"
            alt="Company logo"
            class="img-fluid"
            style="max-height: 30px"
          />
          <div
            v-if="
              checkisViewFormbuilder &&
              userSubmissions &&
              userSubmissions.length > 0 &&
              this.$route.name !== 'ApplicationFormbuilderExecuteStep'
            "
          >
            <el-button type="text" @click="gotoAllSubmissions"
              >Go to all my submissions</el-button
            >
          </div>
        </div>
      </div>
      <div
        v-if="formbuilderDetails.steps && formbuilderDetails.steps.length !== 1"
      >
        <div
          class="mt-1"
          v-if="
            formbuilderDetails &&
            formbuilderDetails.steps &&
            formbuilderDetails.steps.length > 4
          "
        >
          <el-scrollbar wrap-style="max-width: 1300px;">
            <el-steps
              :active="parseInt(step) + 1"
              :space="200"
              finish-status="success"
              align-center
              size="mini"
              class="formbuilder-flow"
            >
              <el-step
                v-for="(st, i) of formbuilderDetails.steps"
                :key="'step_' + i"
                :title="getStepTitle(st)"
                :style="stepStyle"
                @click.native="gotoStep(i)"
              ></el-step>
            </el-steps>
          </el-scrollbar>
        </div>
        <div v-else>
          <el-steps
            :active="parseInt(step) + 1"
            :space="400"
            finish-status="success"
            align-center
            size="mini"
            class="formbuilder-flow"
          >
            <el-step
              v-for="(st, i) of formbuilderDetails.steps"
              :key="'step_' + i"
              :title="getStepTitle(st)"
              :style="stepStyle"
              @click.native="gotoStep(i)"
            ></el-step>
          </el-steps>
        </div>
      </div>
      <div class="main-step-holder" v-if="showData">
        <templatePreview
          v-if="showData && getCurrentStep && getCurrentStep.type == 'TEMPLATE'"
          :currentStepIndex="step"
          :templateData="getCurrentStep.template"
          :isExecute="isEdit"
          :entityDataId="null"
          :currentStep="getCurrentStep"
          :formbuilderDetails="formbuilderDetails"
          :currentFormbuilderData="currentFormbuilderData"
          :currentFormbuilderDataObj="currentFormbuilderDataObj"
          v-on:goto-next="moveToNextTemplate"
          v-on:fetchFormbuilderData="fetchFormbuilderData"
          :companyDetails="companyDetails"
        ></templatePreview>
        <div v-else-if="showData && getCurrentStep.type == 'ENTITY'">
          <entity-template-view
            :currentEntity="getCurrentStep.entity"
            :isEdit="isEdit"
            :layout="'PROFILE'"
            :isApplicationUserSide="false"
            :formbuilderDetails="formbuilderDetails"
            :currentFormbuilderData="currentFormbuilderData"
            :currentStepIndex="step"
            :currentStepData="getCurrentStep"
            v-on:goto-next="moveToNextTemplate"
            v-on:fetchFormbuilderData="fetchFormbuilderData"
            :companyDetails="companyDetails"
          >
          </entity-template-view>
        </div>
      </div>
    </div>
    <div v-else-if="isExpiredFormbuilder">
      <el-card :style="{ backgroundColor: '#f0f8ff' }">
        <div class="expiration-card">
          <el-col :span="2">
            <i
              class="el-icon-timer timer-icon"
              :style="getIsMobile ? 'font-size: 40px;' : 'font-size: 55px;'"
            ></i>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-row>
              <p class="expiration-text">
                {{ formbuilderDetails.name }} Form has expired on
                {{ currentFormbuilderData.expires_at | globalDateTimeFormat }}
              </p>
            </el-row>
            <el-row>
              <div class="additional-msg">
                <ul>
                  <li>
                    Sorry for the inconvenience. Please contact the form owner
                    at:
                  </li>
                  <li
                    v-if="
                      currentFormbuilderData &&
                      currentFormbuilderData.company_owner_email
                    "
                  >
                    <p style="color: #007bff">
                      {{ currentFormbuilderData.company_owner_email }}
                    </p>
                  </li>
                </ul>
              </div>
            </el-row>
          </el-col>
        </div>
      </el-card>
    </div>
    <div v-else-if="!loading" class="centered-box">
      <p>Something went wrong</p>
    </div>
    <el-drawer
      :title="
        formbuilderDetails && formbuilderDetails.name
          ? formbuilderDetails.name
          : ''
      "
      :with-header="false"
      :visible.sync="description_shown"
      direction="ttb"
      class="drawer-modal"
      :before-close="checkContinueClicked"
      v-if="
        getFormBuilder &&
        getFormBuilder.customization &&
        getFormBuilder.customization.show_initial_confirmation
      "
    >
      <div class="esigns_logo">
        <img
          v-if="companyLogo"
          :src="companyLogo"
          alt="Nimble logo"
          style="max-height: 30px; margin-top: 15px; margin-left: 25px"
        />
        <img
          v-else
          style="width: 130px; margin-top: 15px; margin-left: 25px"
          src="@/assets/img/logo-white.svg"
        />
      </div>
      <div class="drawer-body">
        <p
          style="color: #303133; font-weight: 400; font-size: 18px"
          class="fw-500"
          v-if="formbuilderDetails"
        >
          {{ formbuilderDetails.name }}
        </p>
        <el-scrollbar wrap-style="max-height: 100px;">
          <p
            style="
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: #303133;
            "
          >
            <span
              v-if="formbuilderDetails && formbuilderDetails.description"
              v-html="formbuilderDetails.description"
              class="description"
            ></span>
            <span v-else>Hello, Please click on Continue</span>
          </p>
        </el-scrollbar>
        <el-divider class="mt-0"></el-divider>
        <el-button
          type="primary"
          size="mini"
          style="border-radius: 2px"
          @click="continueFilling"
          >Continue</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import config from "@/config/app";
import chroma from "chroma-js";

export default {
  name: "FormbuilderNewView",
  components: {
    templatePreview: () => import("./formbuilderTemplatePreview"),
    EntityTemplateView: () => import("./formbuilderEntityView.vue"),
  },
  mixins: [NavigationHelper, FormbuilderHelper, MobileRelatedHelper],
  data() {
    return {
      companyLogo: null,
      stepStyle: {
        width: "200px", // Default width for the steps
      },
      formbuilderId: "",
      step: 0,
      formbuilderCode: "",
      formbuilderDetails: null,
      loading: false,
      logoTwoLoading: true,
      showData: false,
      isEdit: true,
      currentFormbuilderData: null,
      currentFormbuilderDataObj: null,
      userSubmissions: [],
      userSubmissionsCount: 0,
      description_shown: false,
      continue_clicked: false,
      hexConvertedColor: "#F4F9FE",
      companyDetails: null,
      isExpiredFormbuilder: false,
    };
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("company", ["getSingleCompanyLogo", "getCompanyDetails"]),
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getFormBuilderRemindersSettings",
      "getFormBuilderUpdateStatus",
      "getFormBuilderErrors",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getAuthenticationStatus",
      "getWorkspaceList",
      "getUserType",
      "getSwitchWorkspaceError",
      "getPermissionList",
      "getPermissions",
      "getGlobalFiltervalue",
      "getAuthenticationDetails",
      "getUserTypeList",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
    ]),
    ...mapGetters("formbuilderData", [
      "getFormbuilderDataCreateByTemplateStatus",
      "getNewFormbuilderData",
      "getNewFormbuilderDataError",
      "getUserFormbuilderData",
      "getGuestUserFormbuilderSubmissions",
      "getGuestUserFormbuildersList",
    ]),
    ...mapGetters("entityRelationships", ["getEntityAllRelationshipData"]),
    ...mapGetters("entities", ["getEntityPrimaryData"]),
    checkIsRepeatable() {
      if (this.getCurrentStep?.settings?.is_repeatable) {
        return true;
      }
      return false;
    },
    getCurrentStep() {
      if (this.formbuilderDetails?.steps) {
        return this.formbuilderDetails.steps[this.step];
      }
      return null;
    },
    checkisViewFormbuilder() {
      return this.formbuilderDetails?.settings?.view_responses;
    },
    getAllSteps() {
      if (this.formbuilderDetails?.steps) {
        return this.formbuilderDetails.steps;
      }
      return [];
    },
    isAnonymousForm() {
      return this.formbuilderDetails?.form_builders_owner === "ANONYMOUS";
    },
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$route && this.$route.query && this.$route.query.payment) {
      this.redirectFromPayment = true;
    }
    //check is edit or not
    if (
      this.$route.name == "GuestFormbuilderApprovalExecute" ||
      this.$route.name == "GuestFormbuilderView" ||
      this.$route.name == "GuestFormbuilderExecuteViewStep"
    ) {
      this.isEdit = false;
    }
    await this.getNecessaryInfo();
    this.showData = true;
    window.addEventListener("resize", this.adjustStepWidth);
    this.adjustStepWidth();
    this.getCompanyInfo();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustStepWidth);
  },
  methods: {
    async continueFilling() {
      this.continue_clicked = true;
      this.description_shown = false;
    },
    checkContinueClicked() {
      if (this.continue_clicked) {
        this.description_shown = false;
      }
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        if (this.getCompanyDetails) {
          this.brandingInfo = {
            ...this.getCompanyDetails,
          };
          await Promise.all([this.applyTheme()]);
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      } finally {
        this.loading = false;
      }
    },
    async applyTheme() {
      // Apply theme using company info
      // Ensure that this.companyInfo is set before applying theme
      document.documentElement.style.setProperty(
        "--primary-color",
        this.getCompanyDetails.dialog_header_color
      );
      document.documentElement.style.setProperty(
        "--primary-contrast-color",
        this.getCompanyDetails.dialog_header_text_color
      );
      document.documentElement.style.setProperty(
        "--sidemenu-background-color",
        this.getCompanyDetails.sidemenu_background
      );
      document.documentElement.style.setProperty(
        "--sidemenu-text-color",
        this.getCompanyDetails.sidemenu_text
      );
      const primaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--primary-color")
        .trim();
      const lighterPrimaryColor = chroma(primaryColor).brighten(0.5).hex();
      const darkerPrimaryColor = chroma(primaryColor).darken(0.5).hex();
      document.documentElement.style.setProperty(
        "--darker-primary-color",
        darkerPrimaryColor
      );
      document.documentElement.style.setProperty(
        "--lighter-primary-color",
        lighterPrimaryColor
      );
      // document.documentElement.style.setProperty('--Hover-color-inputfield', this.companyInfo.menubackground);
      // document.documentElement.style.setProperty('--text-secondary-color', 'white');

      const secondaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--secondary-color")
        .trim();

      const darkerSecondaryColor = chroma(secondaryColor).brighten(0.3).hex();

      document.documentElement.style.setProperty(
        "--darker-secondary-color",
        darkerSecondaryColor
      );
    },
    getCurrentEntityExistedData() {
      return (step) => {
        if (
          step?.settings?.isLoginEntity &&
          this.getAuthenticatedUser?.account_data_id &&
          this.getFormBuilder?.form_builders_owner == "ENTITY"
        ) {
          return this.getAuthenticatedUser.account_data_id;
        }
        const formbuilderTemplateData = this.getFormBuilder?.steps.find((e) => {
          let id = e.entity && e.entity._id ? e.entity._id : e.entity;
          if (id == step.entity._id || id == step.entity) {
            return e;
          }
        });
        const currenctTemplateData = (
          this.getUserFormbuilderData?.formbuilder_steps_data || []
        ).find((e) => {
          let id =
            e.entity_id && e.entity_id._id ? e.entity_id._id : e.entity_id;
          if (id == step.entity._id || id == step.entity) {
            return e;
          }
        });
        if (this.formbuilderDataId && currenctTemplateData) {
          return (
            currenctTemplateData?.entity_data_id ||
            currenctTemplateData?.entityDataId
          );
        }
        return (
          formbuilderTemplateData?.entityDataId ||
          formbuilderTemplateData?.entity_data_id ||
          step.entityDataId
        );
      };
    },
    gotoAllSubmissions() {
      this.$router.push({
        name:
          this.$route.name == "ApplicationFormbuilderExecuteStep"
            ? "ApplicaionFormbuilderUserSubmittedDataList"
            : "GuestFormbuilderUserSubmittedDataList",
        params: {
          formbuilder_details_id: this.$route.params.formbuilder_details_id,
          user_id: this.getAuthenticatedUser._id,
        },
      });
    },
    async fetchFormbuilderData(id) {
      this.loading = true;
      this.formbuilderDataId = id;
      await this.$store.dispatch("formbuilderData/fetchUserFormbuilderData", {
        id: this.formbuilderDataId,
        include_template_data: true,
        include_contacts_data: true,
      });
      if (this.getUserFormbuilderData?._id) {
        this.currentFormbuilderData = this.getUserFormbuilderData;
        if (this.currentFormbuilderData?.formbuilder_data_status == "EXPIRED") {
          this.isExpiredFormbuilder = true;
          return;
        }
        if (this.currentFormbuilderData?.is_approval_form) {
          if (this.currentFormbuilderData?.approval_status == "APPROVED") {
            this.isEdit = false;
          } else if (
            this.currentFormbuilderData?.approval_status == "REJECTED"
          ) {
            if (
              this.getAuthenticatedUser._id ==
              this.currentFormbuilderData.contact
            ) {
              if (this.formbuilderDetails?.settings?.edit_responses) {
                this.isEdit = true;
              } else {
                this.isEdit = false;
              }
            }
          } else if (
            this.checkReviewer(this.currentFormbuilderData) &&
            this.getAuthenticatedUser._id == this.currentFormbuilderData.contact
          ) {
            this.isEdit = false;
          }
        }
        if (this.currentFormbuilderData.formbuilder_steps_data) {
          let entityDataIds = [],
            entityDataFormbuilderData = {};
          this.currentFormbuilderData.formbuilder_steps_data.forEach(
            (element) => {
              if (element?.entity_data_id) {
                entityDataIds.push(element.entity_data_id);
              }
            }
          );
          if (entityDataIds.length > 0) {
            await Promise.all(
              entityDataIds.map(async (id) => {
                await this.$store.dispatch(
                  "entities/fetchEntityPrimaryDataById",
                  {
                    id: id,
                  }
                );
                if (this.getEntityPrimaryData?._id) {
                  let templateData = {};
                  this.getEntityPrimaryData.templates_data.forEach((t) => {
                    if (t?.template_data_id?.template_data) {
                      templateData[t.template_id] =
                        t.template_data_id.template_data;
                    }
                  });
                  entityDataFormbuilderData[id] = templateData;
                }
              })
            );
          }
          this.currentFormbuilderDataObj = {};
          this.currentFormbuilderData.formbuilder_steps_data.forEach(
            (element, i) => {
              if (element?.template_data_id?.template_data) {
                this.currentFormbuilderDataObj[i] = {
                  [element.template_data_id.template_id]:
                    element?.template_data_id?.template_data,
                };
              } else if (
                element?.entity_data_id &&
                entityDataFormbuilderData[element.entity_data_id]
              ) {
                this.currentFormbuilderDataObj[i] =
                  entityDataFormbuilderData[element.entity_data_id];
              }
            }
          );
        }
      }
      this.loading = false;
    },
    adjustStepWidth() {
      const screenWidth = window.innerWidth;
      // Adjust the width based on screen size using media queries or other logic
      if (screenWidth < 768) {
        // For small screens, adjust the width accordingly
        this.stepStyle.width = "100px";
      } else if (screenWidth < 992) {
        // For medium-sized screens, adjust the width accordingly
        this.stepStyle.width = "200px";
      } else {
        // For larger screens, use the default width (120px in this case)
        this.stepStyle.width = "300px";
      }
    },
    gotoSuccessPage() {
      this.$router.push({
        name:
          this.$route.name == "ApplicationFormbuilderExecuteStep"
            ? "ApplicaionFormbuilderCompleted"
            : "GuestFormbuilderCompleted",
        params: {
          formbuilder_code: this.formbuilderDetails.code,
          formbuilder_details_id: this.formbuilderDetails._id,
          formbuilder_data_id: this.getNewFormbuilderData._id,
        },
      });
    },
    async gotoStep(step) {
      this.showData = false;
      this.loading = true;
      this.step = step;
      let query = this.getNavigationQuery(this.$route.query);
      query["step"] = this.step;
      this.$router.push({
        name: this.$route.name,
        query: query,
      });
      setTimeout(() => {
        this.showData = true;
        this.loading = false;
      }, 1000);
    },
    async moveToNextTemplate(step) {
      if (this.step + 1 == this.getAllSteps.length) {
        if (
          this.formbuilderDetails.settings &&
          this.formbuilderDetails.settings.success_and_redirect_page &&
          this.formbuilderDetails.settings.success_and_redirect_page
            .redirect_to &&
          this.formbuilderDetails.settings.success_and_redirect_page
            .redirect_to_url
        ) {
          if (
            this.isValidUrl(
              this.formbuilderDetails.settings.success_and_redirect_page
                .redirect_to_url
            ) &&
            this.isValidHttpUrl(
              this.getValidUrl(
                this.formbuilderDetails.settings.success_and_redirect_page
                  .redirect_to_url
              )
            )
          ) {
            window.location.href = this.getValidUrl(
              this.formbuilderDetails.settings.success_and_redirect_page
                .redirect_to_url
            );
          } else {
            this.gotoSuccessPage();
          }
        } else {
          this.gotoSuccessPage();
        }
      } else {
        await this.gotoStep(step);
      }
    },
    isValidUrl(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    getStepTitle(step) {
      let title = "step";
      if (step) {
        if (
          step?.settings?.receiver_title &&
          (step?.type == "TEMPLATE" || step?.type == "ENTITY")
        ) {
          title = step?.settings?.receiver_title;
        } else if (step?.type == "TEMPLATE" && step?.template?.name) {
          title = step?.template?.name;
        } else if (step?.type == "ENTITY") {
          title = step?.entity?.name;
        }
      }
      return this.truncateString(title, this.getIsMobile ? 10 : 24);
    },
    truncateString(str, maxLength) {
      if (
        str &&
        typeof str === "string" &&
        typeof maxLength === "number" &&
        maxLength > 0
      ) {
        return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
      } else {
        return "";
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.formbuilderId = this.$route.params.formbuilder_details_id;
      this.step = this.$route.query.step || 0;

      this.formbuilderCode = this.$route.params.formbuilder_code;
      await Promise.all([
        this.$store.dispatch("formBuilders/fetchFormBuilder", {
          id: this.formbuilderId,
          include_template_details: true,
          include_entity_details: true,
          include_documents_details: true,
        }),
        this.$store.dispatch("formbuilderData/fetchGuestUserFormbuildersData", {
          formbuilder_details_id: this.formbuilderId,
          user_id: this.$route.query.user_id || this.getAuthenticatedUser._id,
        }),
      ]);
      if (this.getFormBuilder) {
        this.formbuilderDetails = { ...this.getFormBuilder };
        if (this.getGuestUserFormbuildersList) {
          this.userSubmissions = this.getGuestUserFormbuildersList;
          this.userSubmissionsCount = this.getGuestUserFormbuilderSubmissions;
        } else {
          this.userSubmissions = [];
          this.userSubmissionsCount = 0;
        }
        await Promise.all([
          await this.$store.dispatch(
            "entityRelationships/fetchEntityAllRelationshipData",
            {
              parent_entity_id: this.formbuilderDetails.entity_id,
              parent_entity_data_id: this.getAuthenticatedUser?.account_data_id,
            }
          ),
          this.fetchSingleCompanyLogo(this.formbuilderDetails.company),
        ]);
        this.formbuilderDetails.steps = this.formbuilderDetails.steps.map(
          (e, i, arr) => {
            if (e?.entity && e.type == "ENTITY") {
              if (e.entity?.templates) {
                e.entity.templates = e.entity.templates.map((t) => {
                  t["templateInfo"] = t.template_id;
                  t.template_id =
                    t.template_id && t.template_id._id
                      ? t.template_id._id
                      : t.template_id;
                  return t;
                });
              }
              if (
                e?.settings?.isLoginEntity &&
                this.getAuthenticatedUser?.account_data_id
              ) {
                e.entityDataId = this.getAuthenticatedUser?.account_data_id;
              } else if (e?.settings?.applyRelationship) {
                let currentStep = (
                  this.getEntityAllRelationshipData || []
                ).find(
                  (e) =>
                    e.relationship_id == e?.entity?._id ||
                    e.relationship_id == e.entity
                );
                if (
                  currentStep &&
                  (currentStep[currentStep?.relation_type] == "ONE" ||
                    this.currentTemplateIndex > 0) &&
                  currentStep?.entity_data_ids &&
                  currentStep.entity_data_ids.length
                ) {
                  return currentStep?.entity_data_ids &&
                    currentStep.entity_data_ids.length
                    ? currentStep.entity_data_ids[
                        currentStep.entity_data_ids.length - 1
                      ]
                    : "";
                }
              } else {
                e.entityDataId = "";
              }
            }
            if (i < arr.length - 1) {
              e.has_next = true;
            }
            return e;
          }
        );
        if (this.$route?.query?.formbuilder_data_id) {
          await this.fetchFormbuilderData(
            this.$route?.query?.formbuilder_data_id
          );
        } else {
          this.description_shown = true;
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    async fetchSingleCompanyLogo(id) {
      this.logoTwoLoading = true;
      await Promise.all([
        this.$store.dispatch("company/fetchCompanyLogo", id),
        this.$store.dispatch("company/fetchCompany", id),
      ]);
      if (this.getCompanyDetails?.button?.background) {
        this.companyDetails = this.getCompanyDetails;
        this.hexConvertedColor = this.hexToRgb(
          this.companyDetails?.button?.background
        );
      }

      if (this.getSingleCompanyLogo) {
        this.companyLogo = `${config.S3_BUCKET_URL}/${this.getSingleCompanyLogo}`;
      } else {
        this.companyLogo = null;
      }

      this.logoTwoLoading = false;
    },
    hexToRgb(hex) {
      hex = hex.replace("#", "");
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, 0.1)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e6e9ea;
  .centered-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80vw;
    width: 80%;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    height: 100%;
    overflow-y: hidden;

    @media (max-width: 1200px) {
      max-width: 100%;
      width: 100%;
      height: 100vh;
      position: fixed;
      touch-action: pan-up;
    }

    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      top: 0%;
      border-bottom: 1px solid #e6e6e6;

      /* for adding divider*/
      .title {
        font-size: 22px;
        font-weight: bold;
      }
    }

    .main-step-holder {
      width: 100%;
      height: 100% !important;
      //border-top: 1px solid #e6e6e6;
    }
  }
}

.image-container {
  /* Optional: Add padding or other styles if needed */
}

.image-container img {
  max-height: 40px;
}

.el-steps {
  display: flex;
  justify-content: flex-start;
  //border-bottom: 1px solid #e6e6e6;
}

.el-step {
  /* The width will be set dynamically using Vue.js and media queries */
}

@media (max-width: 767px) {
  .el-step {
    width: 80px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .el-step {
    width: 100px;
    /* For medium-sized screens, adjust the width accordingly */
  }
}

.mobile-margin {
  margin-top: 20px;
}

.esigns_logo {
  background: var(--primary-color);
  height: 65px;
}

.drawer-body {
  padding: 25px;
}

.drawer-modal {
  height: 1000px !important;

  @media (max-width: 991.98px) {
    height: 1500px !important;
  }
}

.horizontal-scroll-container {
  width: 100%;
  overflow-x: auto;
}

.scroll-wrap {
  display: flex;
  white-space: nowrap;
}

.scroll-view {
  flex-shrink: 0;
}

.expiration-card {
  align-items: center;
  padding-top: 10px;
  padding-bottom: 30px;
  border-radius: 4px;
}

.timer-icon {
  margin-right: 10px;
  color: #dc3545;
}

.expiration-text {
  font-size: 18px;
  font-weight: bold;
  color: #dc3545;
}
.additional-msg {
  padding-top: 10px;
  padding-bottom: 25px;
  font-size: 15px;
}

@media screen and (min-width: 1000px) {
  .fullscreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #e6e9ea;
    .centered-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 90vw;
      width: 90%;
      background-color: white;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      height: 100%;
      overflow-y: clip;

      @media (max-width: 1200px) {
        max-width: 100%;
        width: 100%;
        height: 100vh;
        overflow: hidden;
      }

      .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        top: 0%;
        border-bottom: 1px solid #e6e6e6;

        /* for adding divider*/
        .title {
          font-size: 22px;
          font-weight: bold;
        }
      }

      .main-step-holder {
        width: 100%;
        height: 100% !important;
        //border-top: 1px solid #e6e6e6;
      }
    }
  }
}
</style>
