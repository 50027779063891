import { render, staticRenderFns } from "./formBuilderNewView.vue?vue&type=template&id=ba3932cc&scoped=true"
import script from "./formBuilderNewView.vue?vue&type=script&lang=js"
export * from "./formBuilderNewView.vue?vue&type=script&lang=js"
import style0 from "./formBuilderNewView.vue?vue&type=style&index=0&id=ba3932cc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba3932cc",
  null
  
)

export default component.exports